import { borderRadius, flexbox, fontbox, fullWidth, paddingXY } from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

export const css = {
  feedback: {
    ...fontbox(appFonts.inter, 12, 400, 12),
    color: appColors.reds.base,
    marginBottom: 8,
    marginTop: -20,
  },
  subheader: {
    ...fontbox(appFonts.inter, 16, 400, 24),
    color: appColors.grays[700],
    marginBottom: 8,
  },

  form: {
    ...flexbox('column', 'flex-start', 'flex-start', 16),

    inner: {
      ...flexbox('row', 'flex-start', 'flex-start', 8, 'wrap'),
      ...fullWidth(),

      "> label": {
        ...fontbox(appFonts?.inter, 14, 700, 24),
        ...fullWidth(),
        color: appColors?.grays?.[700],
      },
    },
  },

  error: {
    ...borderRadius(4),
    ...fontbox(appFonts?.inter, 14, 400, 18),
    ...paddingXY(8),
    border: `1px solid #E98DA1`,
    color: appColors?.reds?.errorPrimary,
  },
}
