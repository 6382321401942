import {
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  marginX,
  paddingX,
  paddingY,
  posRelative,
} from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

export const css = isError => ({
  ...flexbox('column', 'flex-start', 'flex-start', 2),
  ...fullWidth(),

  '> *': {
    ...fullWidth(),
    ...posRelative(null, null, null, null, 1),
  },

  labelBlock: {
    ...flexbox('column', 'flex-start', 'flex-start'),

    label: {
      ...fontbox(appFonts?.inter, 14, 700, 24),
      color: appColors?.grays?.[700],

      '> span': {
        color: appColors?.reds?.base,
      },
    },
  },

  input: {
    ...borderRadius(4),
    ...fontbox(appFonts?.inter, 14, 400, 24),
    ...fullWidth(),
    ...paddingX(12),
    border: `1px solid ${isError ? `#E98DA1` : appColors?.grays?.tabActive}`,
    color: appColors?.grays?.[900],

    '&:focus-visible': {
      borderColor: appColors.grays[900],
    },
  },

  select: {
    ...borderRadius(4),
    ...fontbox(appFonts?.inter, 14, 400, 24),
    ...fullWidth(),
    ...paddingX(12),
    border: `1px solid ${appColors?.grays?.tabActive}`,
    color: appColors?.grays?.[900],

    '&:focus-visible': {
      borderColor: appColors.grays[900],
    },
  },

  dialogHeader: {
    ...fontbox(appFonts.inter, 18, 700, 16),
  },

  feedback: {
    ...borderBottomLeftRadius(4),
    ...borderBottomRightRadius(4),
    ...fontbox(appFonts?.inter, 14, 400, 20),
    color: appColors?.reds?.errorPrimary,
    marginTop: 4,
    width: 'auto',
  },
})
