export const appColors = {
  grays: {
    0: '#FFFFFF',
    50: '#F6F6F6',
    100: '#E6E6E6',
    150: '#D9D9D9',
    200: '#CCCCCC',
    300: '#B3B3B3',
    400: '#727678',
    500: '#808080',
    510: '#838383',
    600: '#666666',
    700: '#4D4D4D',
    800: '#333333',
    900: '#07161D',
    1: '#000000',

    border: '#959B9D',
    border2: '#BBC1C5',

    tabActive: '#E6E9EA',
  },

  blues: {
    base: '#3142AF',
    primary: {
      200: '#81C9EE',
    },
    tertiary: {
      600: '#475467',
    },
    pill: '#F9FAFB',
    pillborder: '#EAECF0',
    pillactive: '#2D576B',
    pillactivebg: '#D7EBF9',
    pillborderactive: '#59A3C5',

    abbey: '#505354',
  },

  reds: {
    base: '#D52C51',
    secondary: '#CF223B',
    destructive: '#911426',

    brick: '#D52C51',

    errorPrimary: '#AA223F',
  },

  greens: {
    base: '#193542',
    secondary: '#2B7B7B',
    navbuttonactivebg: '#07161D',
    hightight: 'rgba(189, 252, 253,  0.05)',
    textlight: '#BDFCFD',
  },
}
