/** @jsxImportSource @emotion/react */
import { useMemo, useContext, useState, useRef } from "react";
import { Formik, Form } from "formik";
import { Dialog } from 'primereact/dialog';
import * as Yup from 'yup';
import { debounce } from 'lodash';

import { useMutation } from '../../hooks';
import { useQuery } from '../../hooks';
import Client, { ENDPOINT } from '../../client';
import AuthContext from '../../context/auth-context';

import ModalButtonSet from '../modal-set-button/modal-set-button';
import FormCheckBox from '../form-check-box/form-check-box';
import FormTextInput from "../../components/form-text-input/form-text-input";
import FormSelect from '../../components/form-select/form-select-simplified';

import { appColors } from '../../emotion/appColors';
import { css } from "./css";

export default function EditUserModal({
  setShowModal = () => {},
  open,
  onSuccess = () => {},
  user
}) {
  const handleCancel = () => {
    setShowModal(false)
  }

  const { runProtected, userData } = useContext(AuthContext);
  const [merging, setMerging] = useState(null)
  const [taken, setTaken] = useState(false)
  const formikRef = useRef(null);

  const duplicateTest = async (field, value) => {//=> debounce(async (value) => {
    try {
      const res = await runProtected(Client.get, `/users?${field}=${value}`);

      const sameUser = res?.data?.find((element) => element.id === user?.id && element.barn_id === userData.barn_id)
      const merge = !user?.id && res?.data?.find((element) => element.barn_id !== userData.barn_id)

      const available = (res?.data?.size || 0) < 1 || sameUser 

      // res?.data?.forEach(element => {
      //   if(available)return;
      //   if(element.barn_id === null && element.is_admin && user?.id !== element.id)available=false //An Admin owns this email address and not editing the admin
      //   else if(element.id === user?.id && element.barn_id === userData.barn_id) {
      //     available = true //Case where we are editing
      //   } else if(element.id !== user?.id && element.barn_id === userData.barn_id) {
      //     //available= available || false //Same Barn, but different user
      //   }
      //   if(element.barn_id !== userData.barn_id && !user?.id)merge = element //Creating a new user and the email is in use by a user in another barn. Merge
      //   //if(element.barn_id !== userData.barn_id && user?.id && !tooken)tooken = true //Only allow merging if we are creating a new user
        
      // });
      if(merge) {
        formikRef.current.values["first_name"] = merge.first_name 
        formikRef.current.values["last_name"] = merge.last_name
        if(!merging)formikRef.current.validateForm()
      }else if(merging) {
        formikRef.current.values["first_name"] = null 
        formikRef.current.values["last_name"] = null
      }
      setMerging(merge)
      setTaken(!available)
      return available;
    } catch (err) {
      console.log(err);
      return true;
    }
  }
//, 250);
const editUserSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required')
    .test(
      'duplicate-email',
      "Email is in use by another user",
      async function (value) {
        const returnFromDupe = await duplicateTest('email', value); 
        return returnFromDupe
      }
    ),
    first_name: Yup.string().test('merging_or_required', 'First Name is Required', function(value) {
      return value
    }),
    last_name: Yup.string().test('merging_or_required', 'Last Name is Required', function(value) {
      return value
    }),
});

  const { mutateAsync: submitRequest } = useMutation({
    mutationKey: 'add-user',
    networkMode: 'always',
    mutationFn: async data => {
      const dataToPass = {...data}
      dataToPass.is_admin = data.admin === true || data.admin === 1 ? true : false;
      dataToPass.is_barn_manager = data.manager === true || data.manager === 1 ? true : false;
      dataToPass.is_vet = data.vet === true || data.vet === 1? true : false;
      let id = merging?.id || user?.id

      delete dataToPass.admin
      delete dataToPass.manager
      delete dataToPass.vet
      if(id) {
        await Client.put(`/users/${id}`, dataToPass)
        //if(merging)alert(`Invitation email sent! ${data.first_name} should have an email within a few minutes.`)
      } else {
        await Client.post(`/users`, dataToPass)
        alert(`Invitation email sent! ${data.first_name} should have an email within a few minutes.`)
      }
    },
    onSuccess: values => {
      onSuccess()
      setShowModal(false)
    },
  });

  const horsesUri = useMemo(() => {
    const url = new URL("/horses?orderBy=name", ENDPOINT);
    return url.toString().replace(ENDPOINT, "");
  }, []);

  // get horses
  const { data: horsesData, refetch: refetchHorses } = useQuery(
    horsesUri
  );

  const initialValues = {
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
    phone: user?.phone,
    assign_horse: user?.horses_owned?.map(h => h.id),
    manager: user?.is_barn_manager ? true : false,
    vet: user?.is_vet ? true : false,
    admin: user?.is_admin ? true : false,
  };

  const horses = horsesData?.data.map((h) => ({
    label: h.name,
    value: h.id
  }));

  const header = (
    <span css={css.dialogHeader} className="dialog-header">
      {user? "Edit a User" : "Add a User"}
    </span>
  );

  const footer = (formik) => (
    <ModalButtonSet
      onCancel={handleCancel}
      isSubmitting={formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      disabled={!formik.isValid || JSON.stringify(initialValues).toUpperCase() === (JSON.stringify(formik.values)).toUpperCase()}
      submitButtonText="Save changes"
      submitButtonColor={appColors?.greens?.base}
    />
  );
  
  return (
    <>
      {/* <Modal title="Edit a User" open={isModalOpen} footer={null}> */}
      <Formik
          initialValues={initialValues}
          onSubmit={(d) => {submitRequest(d)}}
          validationSchema={editUserSchema}
          enabledReinitialize
          validateOnBlur
          innerRef={formikRef}
          validateOnChange
        >
          {(formik) => (
            <Dialog
              header={header}
              footer={footer(formik)}
              visible={true}
              style={{ width: 400 }}
              onHide={() => setShowModal(false)}
            >
              <Form css={css?.form}>
                <div css={css?.form?.inner}>
                  <label>Special Permissions:</label>
                  {(userData.is_admin
                    ? ["Admin", "Manager", "Vet"]
                    : ["Manager", "Vet"]).map((role) => {
                      return (
                        <FormCheckBox
                          key={role.toLowerCase()}
                          name={role.toLowerCase()}
                          label={role}
                          formik={formik}
                        />
                      );
                    })}
                </div>
                <FormTextInput
                  formik={formik}
                  name="email"
                  label="Email"
                  onChange={async (e) => {formik.handleChange(e)}}
                  onBlur={async (e) => {formik.handleBlur(e)}}
                  required={true}
                />

                {merging && (
                  <div css={css?.error}>Email is in use by a user in another barn. The existing user will be added to this barn.</div>
                )}

                {!merging && (
                  <>
                    <FormTextInput
                      formik={formik}
                      name="first_name"
                      label="First Name"
                      labelPosition="top"
                      readOnly={merging}
                      required={!merging}
                    />

                    <FormTextInput
                      formik={formik}
                      name="last_name"
                      label="Last Name"
                      labelPosition="top"
                      readOnly={merging}
                      required={!merging}
                    />
                  </>
                )}

                <FormTextInput
                  formik={formik}
                  name="phone"
                  label="Phone"
                  labelPosition="top"
                  readOnly={merging}
                />

                <FormSelect
                  formik={formik}
                  name="assign_horse"
                  label="Assign Horse(s) this user is a guardian for"
                  placeholder="Select All That Apply"
                  options={horses}
                  isMulti={true}
                  onBlur={formik.handleBlur}
                />
              </Form>
            </Dialog>
          )}
      </Formik>
    </>
  );
}